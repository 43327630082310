<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >数据在线加解密</h2>
		<el-row style="margin-top: 4%;">
			<el-col :sm="13" style="margin: 0 auto;">
				<el-form :model="codesData" >
					<el-row>
						<el-col>
							<el-form-item label="">
								<el-input type="textarea" :rows="5" v-model="codesData.data" @input="generate()" placeholder="请输入加解密数据..." ></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :sm="24">
							 <el-form-item label="操作">
							    <el-radio-group v-model="codesData.isencrypt" @change="generate()">
							      <el-radio label="encrypt">加密</el-radio>
							      <el-radio label="decrypt">解密</el-radio>
							    </el-radio-group>
							  </el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :sm="24">
							<el-form-item label="算法">
								<el-radio-group v-model="codesData.checkunit" size="small" @change="generate()">
								  <el-radio-button label="MD5" >MD5</el-radio-button>
								  <el-radio-button label="HmacMD5" >HmacMD5</el-radio-button>
								  <el-radio-button label="BASE64">BASE64</el-radio-button>
								  <el-radio-button label="AES">AES</el-radio-button>
								  <el-radio-button label="DES">DES</el-radio-button>
								  <el-radio-button label="RC4">RC4</el-radio-button>
								  <el-radio-button label="Rabbit">Rabbit</el-radio-button>
								  <el-radio-button label="TripleDES">TripleDES</el-radio-button>
								  <el-radio-button label="SHA1">SHA1</el-radio-button>
								  <el-radio-button label="HmacSHA1">HmacSHA1</el-radio-button>
								  <el-radio-button label="SHA224">SHA224</el-radio-button>
								  <el-radio-button label="SHA256">SHA256</el-radio-button>
								  <el-radio-button label="SHA384">SHA384</el-radio-button>
								  <el-radio-button label="SHA512">SHA512</el-radio-button>
								</el-radio-group>
							</el-form-item> 
						</el-col>
					</el-row>
					<el-row v-if="codesData.pwdShow">
						<el-col>
							<el-form-item label="秘钥">
								<el-input v-model="codesData.pwd" @input="generate()" placeholder="可为空"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row>
						<el-col>
							<el-form-item label="">
								<el-input type="textarea" :rows="8" v-model="codesData.result" placeholder="运行结果..." readonly></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<div style="margin: 0 auto; text-align: center;">
						<el-button  type="primary" @click="textReplacement()">交换</el-button>
						<el-button  type="danger" @click="remove()" plain>清空</el-button>
						<el-button  @click="copy(codesData.result)" type="primary" plain>复制</el-button>
					</div>
				</el-form>
		</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import CryptoJS from "crypto-js";
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	

    let codesData = ref({
		moduleid:2005,
        rows: 10,
        data: "",
		pwd: "",
		checkunit:"MD5",
		isencrypt:"encrypt",
		tempCache:"",
		result:"",
		pwdShow:false,
    })
	moduleaction(codesData.value.moduleid,1);
	
	let generate = () => {
		codesData.value.result="";
		var data=codesData.value.data;
		if(!data){
			return
		}
		var isencrypt=codesData.value.isencrypt;
		var unit=codesData.value.checkunit;
		var pwd=codesData.value.pwd;
		showPwdInput(unit);
		var result=isencrypt=="encrypt" ? encrypt(unit,data,pwd) : decrypt(unit,data,pwd);
		codesData.value.result=result.toString();
	}
	
	//加密
	let encrypt = (unit,source,pwd) => {
		var result;
	 	switch (unit) {
			case "SHA1":
				result=CryptoJS.SHA1(source);
				break;
			case "SHA224":
				result=CryptoJS.SHA224(source);
				break;
			case "SHA256":
				result=CryptoJS.SHA256(source);
				break;
			case "SHA384":
				result=CryptoJS.SHA384(source);
				break;
			case "SHA512":
				result=CryptoJS.SHA512(source);
				break;
			case "MD5":
				result=CryptoJS.MD5(source);
				break;
			case "HmacSHA1":
				result=CryptoJS.HmacSHA1(source,pwd);
				break;
			case "HmacSHA224":
				result=CryptoJS.HmacSHA224(source,pwd);
				break;
			case "HmacSHA256":
				result=CryptoJS.HmacSHA256(source,pwd);
				break;
			case "HmacSHA384":
				result=CryptoJS.HmacSHA384(source,pwd);
				break;
			case "HmacSHA512":
				result=CryptoJS.HmacSHA512(source,pwd);
				break;
			case "HmacMD5":
				result=CryptoJS.HmacMD5(source,pwd);
				break;
			case "BASE64":
				var data = CryptoJS.enc.Utf8.parse(source);
				result=CryptoJS.enc.Base64.stringify(data);
				break;
			case "AES":
				result=CryptoJS.AES.encrypt(source,pwd);
				break;
			case "DES":
				result=CryptoJS.DES.encrypt(source,pwd);
				break;
			case "Rabbit":
				result=CryptoJS.Rabbit.encrypt(source,pwd);
				break;
			case "RC4":
				result=CryptoJS.RC4.encrypt(source,pwd);
				break;
			case "TripleDES":
				result=CryptoJS.TripleDES.encrypt(source,pwd);
				break;
		}
	 	return result;
	}
	
	//解密
	let decrypt = (unit,source,pwd) => {
	 	var result;
	 	switch (unit) {
			case "BASE64":
				result=CryptoJS.enc.Base64.parse(source).toString(CryptoJS.enc.Utf8);
				break;
			case "AES":
				result=CryptoJS.AES.decrypt(source,pwd).toString(CryptoJS.enc.Utf8);
				break;
			case "DES":
				result=CryptoJS.DES.decrypt(source,pwd).toString(CryptoJS.enc.Utf8);
				break;
			case "Rabbit":
				result=CryptoJS.Rabbit.decrypt(source,pwd).toString(CryptoJS.enc.Utf8);
				break;
			case "RC4":
				result=CryptoJS.RC4.decrypt(source,pwd).toString(CryptoJS.enc.Utf8);
				break;
			case "TripleDES":
				result=CryptoJS.TripleDES.decrypt(source,pwd).toString(CryptoJS.enc.Utf8);
				break;
	 	}
	 	return result;
	}
	
	let showPwdInput =(unit) =>{
		switch (unit) {
			case "SHA1":
				codesData.value.pwdShow=false
				break;
			case "SHA224":
				codesData.value.pwdShow=false
				break;
			case "SHA256":
				codesData.value.pwdShow=false
				break;
			case "SHA384":
				codesData.value.pwdShow=false
				break;
			case "SHA512":
				codesData.value.pwdShow=false
				break;
			case "MD5":
				codesData.value.pwdShow=false
				break;
			case "HmacSHA1":
				codesData.value.pwdShow=true
				break;
			case "HmacSHA224":
				codesData.value.pwdShow=true
				break;
			case "HmacSHA256":
				codesData.value.pwdShow=true
				break;
			case "HmacSHA384":
				codesData.value.pwdShow=true
				break;
			case "HmacSHA512":
				codesData.value.pwdShow=true
				break;
			case "HmacMD5":
				codesData.value.pwdShow=true
				break;
			case "BASE64":
				codesData.value.pwdShow=false
				break;
			case "AES":
				codesData.value.pwdShow=true
				break;
			case "DES":
				codesData.value.pwdShow=true
				break;
			case "Rabbit":
				codesData.value.pwdShow=true
				break;
			case "RC4":
				codesData.value.pwdShow=true
				break;
			case "TripleDES":
				codesData.value.pwdShow=true
				break;
		}
	}
	
	let textReplacement =() =>{
		codesData.value.tempCache=codesData.value.result;
		codesData.value.result=codesData.value.data;
		codesData.value.data=codesData.value.tempCache;
	}
	
	let remove = () => {
		codesData.value.pwd="";
		codesData.value.data="";
		codesData.value.result="";
	}
</script>

<style>

</style>